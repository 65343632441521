.labelDiv{
    padding: 3px 7px;
    margin: 4px 0px;
    margin-right: 4px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #e9e8e8;
    display: inline-block;
    border-radius: 3px;
}

/* Header Nav Styles */
.navigationTopContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 24px;
    align-items: center;
    height: 49px;
}
.navigationBottomContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 32px;
    align-items: center;
    height: 86px;
    flex-wrap: wrap;
}
.navigationTopLink {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    position:relative;
    color: #FFFFFF;
}
.navigationTopLink a{
    color: #FFFFFF;
}
.navigationMainLogo{
    flex-grow: 2;
}
@media only screen and (max-width: 595px) {
    .navigationTopContainer{
        display:none;
    }
}

.navigationMobileNav{
    display:none;
}
.navigationDesktopNav{
    display:block;
}
@media only screen and (max-width: 999px){
    .navigationMobileNav{
        display:block;
    }
    .navigationDesktopNav{
        display:none;
    }
}

/* Navigation / Dropdown Styles */
.dropdown {
    position: relative;
}
.dropdownActive {
    position: relative;
}
.dropdownActive .dropdownMenu {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}
.dropdownActive .dropdownMainLink {
    text-decoration: underline;
}
.dropdownMenu{
    position: absolute;
    left: 0;
    top: calc(100% + .25rem);
    padding: 8px; 
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.4);
    opacity: 0;
    pointer-events: none;
    transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
    transform: translateY(-10px);
    background-color: #1D1746ea;
    color: #FFFFFF;
    font-weight: 600;
    padding-right: 20px;
    margin-top: 21px !important;
    z-index: 1000;
}
.dropdownMenuLink{
    font-size: 12px;
    letter-spacing: 0.15em;
    border: none !important;
    margin-top:8px;
    margin-bottom:8px;
    white-space: nowrap;
}
.dropdownMenuLink a {
    color: #FFFFFF;
}
.dropdownMainLink {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    position:relative;
    background: none;
    border: none;
}
.navigationMobileNav .dropdownMenu{
    top: calc(100% - .25rem);
    left: calc(-70vw + 24px);
    width: calc(70vw);

}
.dropdownMobileContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items:flex-start;
    margin-top: 16px;
    flex-wrap: wrap;
    padding-bottom:64px;
}
.dropdownMobileSection{
    width: 45%;
    min-width: 150px;
}
.dropdownMobileHeader{
    border-bottom: 1px solid gray;
    text-transform: uppercase;
    color: #FFFFFF;
    letter-spacing: 0.15em;
}
.dropdownMobileHeader a {
    color: #FFFFFF;
    letter-spacing: 0.15em;
}


/* Footer Styles */
.footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 32px;
    min-height: 410px;
    padding-top: 80px;
    flex-wrap: wrap;
}
.footerLanderContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 32px;
    min-height: 310px;
    padding-top: 80px;
    flex-wrap: wrap;
}
.footerSubContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 32px;
}
.footerLogo{
    flex-grow: 1;
    min-width: 210px;
}
.footerColumn{
    flex-grow: 2;
    color: #EDEFF2;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.15em;
    min-width: 170px;
}
.footerColumnHeader{
    color: #FFFFFF;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
}
.footerLinkSection{
    margin-top: 24px;
}
.footerLink a{
    color: #EDEFF2;
}
.footerSubLine{
    color: #EDEFF2;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.15em;
}

.footerSubLine a{
    color: #EDEFF2;
    text-decoration: none;
}
.footerSocialIcons{
    display: flex;
    flex-direction: row;
    gap: 4px;
}

@media only screen and (max-width: 1136px) {
    .footerLogo{
        text-align:center;
        width: 99vw;
    }
    .footerLinkSection{
        margin-top: -24px;
    }
    .footerContainer {
        padding-top: 24px;
    }
}

@media only screen and (max-width: 900px) {
    .footerColumn:last-child{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footerColumnHeader:last-child{
        display: block;
    }
}


@media only screen and (max-width: 595px) {
    .footerColumn{
        min-width: 300px;
        text-align: center;
        justify-content: center;
    }
    .footerSubContainer {
        gap: 12px;
        justify-content: center;
    }
    .footerColumnHeader{
        display: block;
    }
    .footerLogo{
        text-align:center;
    }
    .footerContainer {
        padding-top: 4px;
    }
    .footerSubLine{
        text-align: center;
    }

    .footerSocialIcons{
        width: 200px;
        margin-right: auto;
        margin-left: auto;
    }
    .footerLinkSection{
        width: 100%;
    }
}


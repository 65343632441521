.heroBackground {
    background-color: black;
    width: 100%;
    position: relative;
}

.heroCover {
    background-image: url('/indexbg.jpg');
    opacity: 0.60;
    background-size: cover;
    height: 100%;
    width: 100%;
    min-height: 500px;
    position: absolute;
}

.heroCover1{
    background-image: url('/hero/hero1.jpg');
}
.heroCover2{
    background-image: url('/hero/hero2.jpg');
}
.heroCover3{
    background-image: url('/hero/hero3.jpg');
}
.heroCover4{
    background-image: url('/hero/hero4.jpg');
}
.heroCover5{
    background-image: url('/hero/hero5.jpg');
}

@media only screen and (max-width: 595px) {
    .heroCover {
        opacity: 0.4;
    }
    .heroCover1{
        background-image: url('/hero/hero-mobile1.jpg');
    }
    .heroCover2{
        background-image: url('/hero/hero-mobile2.jpg');
    }
    .heroCover3{
        background-image: url('/hero/hero-mobile3.jpg');
    }
    .heroCover4{
        background-image: url('/hero/hero-mobile4.jpg');
    }
    .heroCover5{
        background-image: url('/hero/hero-mobile5.jpg');
    }
}

.contentContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    min-height: 500px;
}

.contentDiv {
    font-size: 36px;
    font-weight: 700;
    color: #FFFFFF;
    opacity: 1;
    z-index: 2;
    line-height: 2.5rem;
}

.heroPageCover {
    background-image: url('/indexbg.jpg');
    opacity: 0.75;
    background-size: cover;
    height: 100%;
    width: 100%;
    min-height: 350px;
    position: absolute;
}

.contentPageContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    min-height: 350px;
}

.contentSubText {
    font-size: 16px;
}

.videoContainer {
    height: 500px;
    width: 100%;
    position: relative;
    background-color: black;
}

.videoContainer video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  opacity: 0.75;
}
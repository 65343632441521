@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* Header Nav Styles */
.Navigation_navigationTopContainer__4F_WA {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 24px;
    align-items: center;
    height: 49px;
}
.Navigation_navigationBottomContainer__M5XDl {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 32px;
    align-items: center;
    height: 86px;
    flex-wrap: wrap;
}
.Navigation_navigationTopLink__kXDw_ {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    position:relative;
    color: #FFFFFF;
}
.Navigation_navigationTopLink__kXDw_ a{
    color: #FFFFFF;
}
.Navigation_navigationMainLogo__lnKlN{
    flex-grow: 2;
}
@media only screen and (max-width: 595px) {
    .Navigation_navigationTopContainer__4F_WA{
        display:none;
    }
}

.Navigation_navigationMobileNav__2Niwe{
    display:none;
}
.Navigation_navigationDesktopNav__EO0yU{
    display:block;
}
@media only screen and (max-width: 999px){
    .Navigation_navigationMobileNav__2Niwe{
        display:block;
    }
    .Navigation_navigationDesktopNav__EO0yU{
        display:none;
    }
}

/* Navigation / Dropdown Styles */
.Navigation_dropdown__WML7o {
    position: relative;
}
.Navigation_dropdownActive__FAOrl {
    position: relative;
}
.Navigation_dropdownActive__FAOrl .Navigation_dropdownMenu__Z0jwH {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}
.Navigation_dropdownActive__FAOrl .Navigation_dropdownMainLink__Le6L4 {
    text-decoration: underline;
}
.Navigation_dropdownMenu__Z0jwH{
    position: absolute;
    left: 0;
    top: calc(100% + .25rem);
    padding: 8px; 
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.4);
    opacity: 0;
    pointer-events: none;
    transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
    transform: translateY(-10px);
    background-color: #1D1746ea;
    color: #FFFFFF;
    font-weight: 600;
    padding-right: 20px;
    margin-top: 21px !important;
    z-index: 1000;
}
.Navigation_dropdownMenuLink__L_YZj{
    font-size: 12px;
    letter-spacing: 0.15em;
    border: none !important;
    margin-top:8px;
    margin-bottom:8px;
    white-space: nowrap;
}
.Navigation_dropdownMenuLink__L_YZj a {
    color: #FFFFFF;
}
.Navigation_dropdownMainLink__Le6L4 {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    position:relative;
    background: none;
    border: none;
}
.Navigation_navigationMobileNav__2Niwe .Navigation_dropdownMenu__Z0jwH{
    top: calc(100% - .25rem);
    left: calc(-70vw + 24px);
    width: calc(70vw);

}
.Navigation_dropdownMobileContainer__qOXq5 {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items:flex-start;
    margin-top: 16px;
    flex-wrap: wrap;
    padding-bottom:64px;
}
.Navigation_dropdownMobileSection__qPNUd{
    width: 45%;
    min-width: 150px;
}
.Navigation_dropdownMobileHeader__PpQdv{
    border-bottom: 1px solid gray;
    text-transform: uppercase;
    color: #FFFFFF;
    letter-spacing: 0.15em;
}
.Navigation_dropdownMobileHeader__PpQdv a {
    color: #FFFFFF;
    letter-spacing: 0.15em;
}


/* Footer Styles */
.Navigation_footerContainer__ddu7l {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 32px;
    min-height: 410px;
    padding-top: 80px;
    flex-wrap: wrap;
}
.Navigation_footerLanderContainer___8vrx {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 32px;
    min-height: 310px;
    padding-top: 80px;
    flex-wrap: wrap;
}
.Navigation_footerSubContainer__CmfXP {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 32px;
}
.Navigation_footerLogo__2fdgI{
    flex-grow: 1;
    min-width: 210px;
}
.Navigation_footerColumn__XVSgd{
    flex-grow: 2;
    color: #EDEFF2;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.15em;
    min-width: 170px;
}
.Navigation_footerColumnHeader__ZvoRf{
    color: #FFFFFF;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
}
.Navigation_footerLinkSection__k49kp{
    margin-top: 24px;
}
.Navigation_footerLink__YsxKP a{
    color: #EDEFF2;
}
.Navigation_footerSubLine__RTWCI{
    color: #EDEFF2;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.15em;
}

.Navigation_footerSubLine__RTWCI a{
    color: #EDEFF2;
    text-decoration: none;
}
.Navigation_footerSocialIcons__XB_xr{
    display: flex;
    flex-direction: row;
    gap: 4px;
}

@media only screen and (max-width: 1136px) {
    .Navigation_footerLogo__2fdgI{
        text-align:center;
        width: 99vw;
    }
    .Navigation_footerLinkSection__k49kp{
        margin-top: -24px;
    }
    .Navigation_footerContainer__ddu7l {
        padding-top: 24px;
    }
}

@media only screen and (max-width: 900px) {
    .Navigation_footerColumn__XVSgd:last-child{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Navigation_footerColumnHeader__ZvoRf:last-child{
        display: block;
    }
}


@media only screen and (max-width: 595px) {
    .Navigation_footerColumn__XVSgd{
        min-width: 300px;
        text-align: center;
        justify-content: center;
    }
    .Navigation_footerSubContainer__CmfXP {
        gap: 12px;
        justify-content: center;
    }
    .Navigation_footerColumnHeader__ZvoRf{
        display: block;
    }
    .Navigation_footerLogo__2fdgI{
        text-align:center;
    }
    .Navigation_footerContainer__ddu7l {
        padding-top: 4px;
    }
    .Navigation_footerSubLine__RTWCI{
        text-align: center;
    }

    .Navigation_footerSocialIcons__XB_xr{
        width: 200px;
        margin-right: auto;
        margin-left: auto;
    }
    .Navigation_footerLinkSection__k49kp{
        width: 100%;
    }
}


.Hero_heroBackground__oKprb {
    background-color: black;
    width: 100%;
    position: relative;
}

.Hero_heroCover__Wq7tr {
    background-image: url('/indexbg.jpg');
    opacity: 0.60;
    background-size: cover;
    height: 100%;
    width: 100%;
    min-height: 500px;
    position: absolute;
}

.Hero_heroCover1__D8tWy{
    background-image: url('/hero/hero1.jpg');
}
.Hero_heroCover2__1mv1_{
    background-image: url('/hero/hero2.jpg');
}
.Hero_heroCover3__dX2kX{
    background-image: url('/hero/hero3.jpg');
}
.Hero_heroCover4__wdRSW{
    background-image: url('/hero/hero4.jpg');
}
.Hero_heroCover5__C__rg{
    background-image: url('/hero/hero5.jpg');
}

@media only screen and (max-width: 595px) {
    .Hero_heroCover__Wq7tr {
        opacity: 0.4;
    }
    .Hero_heroCover1__D8tWy{
        background-image: url('/hero/hero-mobile1.jpg');
    }
    .Hero_heroCover2__1mv1_{
        background-image: url('/hero/hero-mobile2.jpg');
    }
    .Hero_heroCover3__dX2kX{
        background-image: url('/hero/hero-mobile3.jpg');
    }
    .Hero_heroCover4__wdRSW{
        background-image: url('/hero/hero-mobile4.jpg');
    }
    .Hero_heroCover5__C__rg{
        background-image: url('/hero/hero-mobile5.jpg');
    }
}

.Hero_contentContainer__3RMW2 {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    min-height: 500px;
}

.Hero_contentDiv__ZgCbA {
    font-size: 36px;
    font-weight: 700;
    color: #FFFFFF;
    opacity: 1;
    z-index: 2;
    line-height: 2.5rem;
}

.Hero_heroPageCover__ZeVen {
    background-image: url('/indexbg.jpg');
    opacity: 0.75;
    background-size: cover;
    height: 100%;
    width: 100%;
    min-height: 350px;
    position: absolute;
}

.Hero_contentPageContainer__FqjUt {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    min-height: 350px;
}

.Hero_contentSubText__4gg05 {
    font-size: 16px;
}

.Hero_videoContainer__BNIp8 {
    height: 500px;
    width: 100%;
    position: relative;
    background-color: black;
}

.Hero_videoContainer__BNIp8 video {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 0;
  opacity: 0.75;
}
.Label_labelDiv__cuvmv{
    padding: 3px 7px;
    margin: 4px 0px;
    margin-right: 4px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #e9e8e8;
    display: inline-block;
    border-radius: 3px;
}

